export const ProPlanIdDetermination = {
  getProPlanNameById: (pre_selected_plan: string) => {
    let selectedProPlanName = "";
    if (
      pre_selected_plan === "Monthly_Pro_Plan" ||
      pre_selected_plan === "Monthly Pro Product"
    ) {
      selectedProPlanName = "MonthlyPlus";
    } else if (
      pre_selected_plan === "Premium_Pro_Plan" ||
      pre_selected_plan === "Premium Pro Product"
    ) {
      selectedProPlanName = "AnnualPremium";
    } else if (
      pre_selected_plan === "Basic_Pro_Plan" ||
      pre_selected_plan === "Basic Pro Product"
    ) {
      selectedProPlanName = "AnnualBasic";
    } else if (
      pre_selected_plan === "Annual_Pro_Plan" ||
      pre_selected_plan === "Annual Pro Product"
    ) {
      selectedProPlanName = "AnnualPlus";
    }

    return selectedProPlanName;
  },
};
