import * as React from 'react';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';

import ApplicationUser from '../../entities/ApplicationUser';
import AppConfig from '../../entities/AppConfig';

interface Props extends RouteComponentProps<{ id: string }> {
    user: ApplicationUser;
    config: AppConfig;
}

type State = {
    redirectToSubscribe: boolean;
    returnUrl: string;
    isFromEmbed: boolean;
};

declare const window: any;
declare const gtag: any;

export default class SubscribeConfirmation extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToSubscribe: false,
            returnUrl: this.props.config.mainSiteUrl,
            isFromEmbed: false
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Payments - Subscription Confirmation";

        let redirectState = this.props.location.state;

        // Unlikely scenario, but user shouldn't be on this page unless they've been redirected, 
        // so redirect back to /subscribe
        if (!redirectState || !this.props.user.proSubscription) {
            this.setState({
                redirectToSubscribe: true
            });
            return;
        }

        if ((redirectState as any).returnUrl) {
            this.setState({
                returnUrl: (redirectState as any).returnUrl
            });
        }

        // We could force the iframe to open links to the base, but since that is controlled by Caleb,
        // I would rather stay explicit and keep the decision in the page.  
        if ((redirectState as any).redirectedFromEmbed) {
            this.setState({
                isFromEmbed: true
            })
        }

        setTimeout(() => {
            const user = this.props.user;
            const proSubscription = user.proSubscription;
  
            window.dataLayer.push({
              'event':'orderComplete',
              'orderValue': user.proSubscription?.nextBillPrice,
              'orderCurrency': user.currency || "USD",
              'orderId': user.id, 
              'ecData': { 'email': user.email, "phone_number": user.phoneNumber?.replace(/\D/g, "")}
            });
    

            if ('gtag' in window) {
                let currencyFromLocalStorage = localStorage.getItem('currency');
                const planName = proSubscription?.planName ?? '';
                
                var sku = '';
                if (planName.indexOf('Premium') >= 0) {
                    sku = '2c92a0fd61e79fd40161fc8fd3264af7';
                }
                else if (planName.indexOf('Basic') >= 0) {
                    sku = '2c92a0fe631cf034016321a3a6ee256c';
                }
                else if (planName.indexOf('Monthly') >= 0) {
                    sku = '2c92a0fb501f2f53015021c3b91c2cf3';
                }
                else {
                    sku = '2c92a0fb501f2f53015021c3b9082cf1';
                }

                window.gaItem = {
                    "id": sku,
                    "name": planName,
                    "brand": "SermonCentral",
                    "category": "PRO Subscription",
                    "quantity": "1",
                    "price": proSubscription?.nextBillPrice,
                };
                window.gaTransaction = {
                    "transaction_id": this.props.user.id,
                    "affiliation": "SermonCentralPro",
                    "value": proSubscription?.nextBillPrice,
                    "currency": this.props.user.currency || "USD",
                    "shipping": "0",
                    "tax": "0",
                    "items": [
                        window.gaItem
                    ],
                    "coupon": (redirectState as any).appliedPromoCode || ''
                };

                gtag('event', 'purchase', window.gaTransaction);
            }

        }, 1000);
    }

    render() {

        if (this.state.redirectToSubscribe) {
            return <Redirect to={{ pathname: "/subscribe" }} />;
        }

        return (
            <div className="row">
                <div className="offset-md-2 col-md-8">
                    <div className="text-center py-5">
                        <h2 className="mb-4">Thank you for signing up for SermonCentral PRO!</h2>
                        <h6 className="mb-4">
                            Return to SermonCentral to use PRO features, manage your subscription from your <a target={this.state.isFromEmbed ? '_PARENT' : undefined} href={this.props.config.accountSiteUrl}>account dashboard</a>, or view your <a target={this.state.isFromEmbed ? '_PARENT' : undefined} href={`${this.props.config.mainSiteUrl}/pro-benefits`}>PRO benefits</a>.
                        </h6>
                        <button className="btn btn-secondary ml-auto mr-auto d-block"><a target={this.state.isFromEmbed ? '_PARENT' : undefined} href={this.state.returnUrl} className="buttonLink">RETURN TO SERMONCENTRAL</a></button>
                    </div>
                </div>
            </div>
        );
    }
}