import ApplicationUser from "../entities/ApplicationUser";
import Country from "../entities/Country";
import { PromoCodeDiscountType } from "../entities/PromoCodeDiscountType";
import ProPlan from "../entities/ProPlan";
import {
  ProSubscriptionTerm,
  ProSubscriptionTier,
} from "../entities/ProSubscription";
import ProSubscriptionPromoCode from "../entities/ProSubscriptionPromoCode";
import { ServerResponse, WebClient } from "./ServiceHelper";

export class ProSubscriptionService {
  static getProPlanPriceIncludingDiscount(
    proPlan: ProPlan,
    appliedPromoCode?: ProSubscriptionPromoCode
  ) {
    var purchasePrice = proPlan.price;

    var finalPrice = purchasePrice;

    if (appliedPromoCode) {
      if (
        !appliedPromoCode.appliesToMonthlyPlus &&
        proPlan.term === ProSubscriptionTerm.Monthly &&
        proPlan.tier === ProSubscriptionTier.Plus
      ) {
        return finalPrice;
      }

      if (
        !appliedPromoCode.appliesToAnnualBasic &&
        proPlan.term === ProSubscriptionTerm.Annual &&
        proPlan.tier === ProSubscriptionTier.Basic
      ) {
        return finalPrice;
      }

      if (
        !appliedPromoCode.appliesToAnnualPlus &&
        proPlan.term === ProSubscriptionTerm.Annual &&
        proPlan.tier === ProSubscriptionTier.Plus
      ) {
        return finalPrice;
      }

      if (
        !appliedPromoCode.appliesToAnnualPremium &&
        proPlan.term === ProSubscriptionTerm.Annual &&
        proPlan.tier === ProSubscriptionTier.Premium
      ) {
        return finalPrice;
      }

      if (appliedPromoCode.discountType === PromoCodeDiscountType.PercentOff) {
        var discountPercentage = appliedPromoCode.discountAmount / 100;
        var discountAmount =
          discountPercentage == 1
            ? purchasePrice
            : purchasePrice * discountPercentage;
        finalPrice = this.roundToTwo(purchasePrice - discountAmount);
      } else if (
        appliedPromoCode.discountType === PromoCodeDiscountType.DollarOff
      ) {
        finalPrice = purchasePrice - appliedPromoCode.discountAmount;
      }
    }

    return Math.max(0, finalPrice);
  }

  static getMonthlyPrice(annualPrice: number) {
    return Math.ceil(annualPrice / 12);
  }

  static async getProPlans() {
    var response = await WebClient.Get<ProPlan[]>(
      "api/ProSubscriptions/GetProPlans"
    );
    if (ServerResponse.isSuccess<ProPlan[]>(response)) {
      return response.data;
    } else {
      return [];
    }
  }

  static async getUpgradeToProPremiumCost() {
    var response = await WebClient.Get<number>(
      "api/ProSubscriptions/GetUpgradeToProPremiumCost"
    );
    if (ServerResponse.isSuccess<number>(response)) {
      return response.data;
    } else {
      return 0;
    }
  }

  static async getUpgradeToProPlusCost() {
    return  WebClient.Get<number>(
      "api/ProSubscriptions/GetUpgradeToProPlusCost"
    );
  }

  static async cancelSubscription(
    cancellationReason: string,
    cancellationDescription: string,
    cancelImmediately: boolean
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/CancelSubscription",
      { cancellationReason, cancellationDescription, cancelImmediately }
    );
  }

  static async downgradeSubscription() {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/DowngradeSubscription",
      null
    );
  }

  static async startCheckout() {
    var response = await WebClient.Post<null>(
      "api/ProSubscriptions/StartCheckout",
      null
    );
    if (ServerResponse.isSuccess<null>(response)) {
      return true;
    } else {
      return false;
    }
  }

  static async subscribeToAnnualBasic(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    promoCode: string,
    proConversionPage: string,
    urlRef: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/SubscribeToAnnualBasic",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        promoCode,
        proConversionPage,
        urlRef,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        region,
        country,
      }
    );
  }

  static async subscribeToAnnualPlus(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    promoCode: string,
    proConversionPage: string,
    urlRef: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string,
    excludeTrial: boolean,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/SubscribeToAnnualPlus",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        promoCode,
        proConversionPage,
        urlRef,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        excludeTrial,
        region,
        country,
      }
    );
  }

  static async bundleAnnualPlusAndSermonMaker(
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/BundleAnnualPlusandSermonMaker",
      {
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        region,
        country,
      }
    );
  }

  static async subscribeToAnnualPremium(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    promoCode: string,
    proConversionPage: string,
    urlRef: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/SubscribeToAnnualPremium",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        promoCode,
        proConversionPage,
        urlRef,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        region,
        country,
      }
    );
  }

  static async subscribeToMonthlyPlus(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    phoneNumber: string,
    promoCode: string,
    proConversionPage: string,
    urlRef: string,
    utmCampaign: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/SubscribeToMonthlyPlus",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        phoneNumber,
        promoCode,
        proConversionPage,
        urlRef,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
        region,
        country,
      }
    );
  }

  static async getCountries() {
    var response = await WebClient.Get<Country[]>(
      "api/ProSubscriptions/GetCountries"
    );
    if (ServerResponse.isSuccess<Country[]>(response)) {
      return response;
    } else {
      return [];
    }
  }

  static async upgradeTrialToPaid(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/UpgradeTrialToPaid",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        region,
        country,
      }
    );
  }

  static async upgradeToProPlus(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/UpgradeToProPlus",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        region,
        country,
      }
    );
  }

  static async upgradeToProPremium(
    creditCardId: string,
    nameOnCard: string,
    creditCardNumber: string,
    cv2: string,
    expirationMonth: number,
    expirationYear: number,
    postalCode: string,
    region?: string,
    country?: string
  ) {
    return await WebClient.Post<ApplicationUser>(
      "api/ProSubscriptions/UpgradeToProPremium",
      {
        creditCardId,
        nameOnCard,
        creditCardNumber,
        cv2,
        expirationMonth,
        expirationYear,
        postalCode,
        region,
        country,
      }
    );
  }

  static async validatePromoCode(
    promoCode: string,
    term: ProSubscriptionTerm,
    tier: ProSubscriptionTier
  ) {
    return await WebClient.Post<ProSubscriptionPromoCode>(
      "api/ProSubscriptions/ValidatePromoCode",
      { promoCode, term, tier }
    );
  }

  static roundToTwo(number: number) {
    return +(Math.round(+(number + "e+2")) + "e-2");
  }
}
