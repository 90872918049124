import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faExpeditedssl,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faAngleDown, faTag } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./app.css";
import AnalyticsPageTracker from "./components/AnalyticsPageTracker";
import { PrivateRoute } from "./components/PrivateRoute";
import AppConfig, { AppConfigEnvironment } from "./entities/AppConfig";
import ApplicationUser from "./entities/ApplicationUser";
import Layout from "./layout";
import SignIn from "./pages/authentication/signIn";
import CancelReason from "./pages/cancel/cancelReason";
import CantCancelSubscription from "./pages/cancel/cantCancel";
import CancelSubscriptionConfirmation from "./pages/cancel/confirmation";
import CancelSubscription from "./pages/cancel/index";
import DowngradeConfirmation from "./pages/downgrade/confirmation";
import FeatureUnavailable from "./pages/featureUnavailable";
import PurchaseChurchMediaSet from "./pages/purchase/churchMediaSet";
import PurchaseChurchMediaSetConfirmation from "./pages/purchase/churchMediaSetConfirmation";
import PurchaseChurchMediaSetSubscriptionConfirmation from "./pages/purchase/churchMediaSetSubscriptionConfirmation";
import CheckoutRouter from "./pages/purchase/index";
import PurchaseMediaItem from "./pages/purchase/mediaItem";
import PurchaseMediaItemConfirmation from "./pages/purchase/mediaItemConfirmation";
import PurchaseMediaItemSubscriptionConfirmation from "./pages/purchase/mediaItemSubscriptionConfirmation";
import PurchasePremiumSermonKit from "./pages/purchase/PremiumSermonKit";
import PurchasePremiumSermonKitConfirmation from "./pages/purchase/PremiumSermonKitConfirmation";
import PurchasePremiumSermonKitSubscriptionConfirmation from "./pages/purchase/PremiumSermonKitSubscriptionConfirmation";
import PurchaseSermonSeriesKit from "./pages/purchase/sermonSeriesKit";
import PurchaseSermonSeriesKitConfirmation from "./pages/purchase/sermonSeriesKitConfirmation";
import PurchaseSermonSeriesKitSubscriptionConfirmation from "./pages/purchase/sermonSeriesKitSubscriptionConfirmation";
import SubscribeAlreadyAMember from "./pages/subscribe/alreadyAMember";
import SubscribeAuthChallenge from "./pages/subscribe/authChallenge";
import SubscribeBillingInfo from "./pages/subscribe/billingInfo";
import SubscribeConfirmation from "./pages/subscribe/confirmation";
import SubscribeCreateAccount from "./pages/subscribe/createAccount";
import SubscribeCreateFullAccountEmbed from "./pages/subscribe/createEmbedAccount";
import SubscribeCreateFullAccountQuickSetup from "./pages/subscribe/createFullAccountQuickSetup";
import SubscribeCreateFullAccountSinglePage from "./pages/subscribe/createFullAccountSinglePage";
import { SubscribeCreateBundledMakerAccount } from "./pages/subscribe/createMakerBundleAccount";
import SubscribeRouter from "./pages/subscribe/index";
import CantUpgradeSubscription from "./pages/upgrade/cantUpgrade";
import UpgradeConfirmation from "./pages/upgrade/confirmation";
import UpgradeRouter from "./pages/upgrade/index";
import UpgradeSubscriptionTier from "./pages/upgrade/upgradeTier";
import UpgradeSubscriptionTrial from "./pages/upgrade/upgradeTrial";
import { AccountService } from "./services/AccountService";
import { AppConfigService } from "./services/AppConfigService";
import { AuthenticationService } from "./services/AuthenticationService";

library.add(faCcAmex);
library.add(faCcDiscover);
library.add(faCcMastercard);
library.add(faCcVisa);
library.add(faPaypal);
library.add(faExpeditedssl);
library.add(faAngleDown);
library.add(faTag);
library.add(faClock as any);

interface AppProps {
  baseUrl: string;
}

interface AppState {
  loading: boolean;
  user: ApplicationUser | null;
  config: AppConfig | null;
  hasPaypal: boolean;
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      config: {
        environment: AppConfigEnvironment.Production,
        googleAnalytics: {
          isEnabled: true,
          trackingId: "UA-75701108-7",
        },
        mainSiteUrl: "https://www.sermoncentral.com",
        accountSiteUrl: "https://account.sermoncentral.com",
        sermonMakerSiteUrl: "https://maker.sermoncentral.com",
        paymentsSiteUrl: "https://payments.sermoncentral.com",
        glooSubscriptionsUrl: "https://app.gloo.us/settings/subscriptions",
      },
      hasPaypal: false,
    };
  }

  componentDidMount() {
    AppConfigService.getAppConfig().then((config) => {
      if (config) {
        this.setState({ config: config });
      }
    });

    AuthenticationService.getAuthenticatedUser()
      .then((user) =>
        this.setState(
          {
            user: user,
          },
          () => {
            if (user) {
              AccountService.hasPayPal()
                .then((data) =>
                  this.setState({ hasPaypal: data || false, loading: false })
                )
                .catch((error) => this.setState({ loading: false }));
            } else {
              this.setState({ loading: false });
            }
          }
        )
      )
      .catch((error) =>
        this.setState({
          user: null,
          loading: false,
        })
      );
  }

  handleUpdateUser(user: ApplicationUser | null) {
    this.setState(
      {
        user: user,
      },
      () => {
        if (user) {
          AccountService.hasPayPal().then((data) =>
            this.setState({ hasPaypal: data || false })
          );
        }
      }
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <img
          className="sc-content-spinner"
          src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif"
        />
      );
    } else {
      return (
        <BrowserRouter basename={this.props.baseUrl}>
          <AnalyticsPageTracker config={this.state.config!}>
            <Layout user={this.state.user}>
              <Switch>
                <Route
                  exact
                  path="/auth/sign-in"
                  render={(routeProps) => (
                    <SignIn
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <Route
                  exact
                  path="/subscribe"
                  render={(routeProps) => (
                    <SubscribeRouter
                      {...routeProps}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <Route
                  exact
                  path="/subscribe/auth-challenge"
                  render={(routeProps) => (
                    <SubscribeAuthChallenge
                      {...routeProps}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <Route
                  exact
                  path="/subscribe/create-account"
                  render={(routeProps) => (
                    <SubscribeCreateAccount
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <Route
                  exact
                  path="/subscribe/create-full-account"
                  render={(routeProps) => (
                    <SubscribeCreateFullAccountSinglePage
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />
                <Route
                  exact
                  path="/subscribe/quick-setup"
                  render={(routeProps) => (
                    <SubscribeCreateFullAccountQuickSetup
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <Route
                  exact
                  path="/subscribe/embed"
                  render={(routeProps) => (
                    <SubscribeCreateFullAccountEmbed
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />
                <Route
                  exact
                  path="/subscribe/bundle"
                  render={() => (
                    <SubscribeCreateBundledMakerAccount
                      config={this.state.config!}
                      user={this.state.user}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />
                <PrivateRoute
                  exact
                  path="/subscribe/already-a-member"
                  user={this.state.user}
                  render={(routeProps) => (
                    <SubscribeAlreadyAMember
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/subscribe/billing-info"
                  user={this.state.user}
                  render={(routeProps) => (
                    <SubscribeBillingInfo
                      {...routeProps}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/subscribe/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <SubscribeConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/feature-unavailable"
                  user={this.state.user}
                  render={(routeProps) => (
                    <FeatureUnavailable
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <Route
                  exact
                  path="/checkout"
                  render={(routeProps) => (
                    <CheckoutRouter
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/media/:id"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseMediaItem
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/media/:id/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseMediaItemConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/media/:id/subscription-confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseMediaItemSubscriptionConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/church-media-set/:id"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseChurchMediaSet
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/church-media-set/:id/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseChurchMediaSetConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/church-media-set/:id/subscription-confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseChurchMediaSetSubscriptionConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/sermon-series-kit/:id"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseSermonSeriesKit
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                      hasPaypal={this.state.hasPaypal}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/sermon-series-kit/:id/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseSermonSeriesKitConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/sermon-series-kit/:id/subscription-confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchaseSermonSeriesKitSubscriptionConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <Redirect
                  exact
                  from="/purchase/total-prep-package/:id"
                  to="/purchase/sermon-series-kit/:id"
                />

                <Redirect
                  exact
                  from="/purchase/total-prep-package/:id/confirmation"
                  to="/purchase/sermon-series-kit/:id/confirmation"
                />

                <Redirect
                  exact
                  from="/purchase/total-prep-package/:id/subscription-confirmation"
                  to="/purchase/sermon-series-kit/:id/subscription-confirmation"
                />
              
                <PrivateRoute
                  exact
                  path="/purchase/premium-sermon-kit/:id"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchasePremiumSermonKit
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                      hasPaypal={this.state.hasPaypal}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/premium-sermon-kit/:id/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchasePremiumSermonKitConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/purchase/premium-sermon-kit/:id/subscription-confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <PurchasePremiumSermonKitSubscriptionConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/cancel"
                  user={this.state.user}
                  render={(routeProps) => (
                    <CancelSubscription
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/cancel-reason"
                  user={this.state.user}
                  render={(routeProps) => (
                    <CancelReason
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/cancel/cant-cancel"
                  user={this.state.user}
                  render={(routeProps) => (
                    <CantCancelSubscription
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/cancel/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <CancelSubscriptionConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <Route
                  exact
                  path="/upgrade"
                  render={(routeProps) => (
                    <UpgradeRouter
                      {...routeProps}
                      config={this.state.config!}
                      hasPaypal={this.state.hasPaypal}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/upgrade/tier"
                  user={this.state.user}
                  render={(routeProps) => (
                    <UpgradeSubscriptionTier
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/upgrade/from-trial"
                  user={this.state.user}
                  render={(routeProps) => (
                    <UpgradeSubscriptionTrial
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                      hasPaypal={this.state.hasPaypal}
                      handleUpdateUser={(user) => this.handleUpdateUser(user)}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/upgrade/cant-upgrade"
                  user={this.state.user}
                  render={(routeProps) => (
                    <CantUpgradeSubscription
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/upgrade/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <UpgradeConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <PrivateRoute
                  exact
                  path="/downgrade/confirmation"
                  user={this.state.user}
                  render={(routeProps) => (
                    <DowngradeConfirmation
                      {...routeProps}
                      config={this.state.config!}
                      user={this.state.user!}
                    />
                  )}
                />

                <Redirect to="/subscribe" />
              </Switch>
            </Layout>
          </AnalyticsPageTracker>
        </BrowserRouter>
      );
    }
  }
}

export default App;
