import React, { useEffect, useState } from "react";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import PostalCodeLocation from "../../entities/PostalCodeLocation";
import ProPlan from "../../entities/ProPlan";
import { AccountService } from "../../services/AccountService";
import { PostalCodeService } from "../../services/PostalCodeService";
import { ProSubscriptionService } from "../../services/ProSubscriptionService";
import {
  FieldValidationError,
  ServerModelValidationResponse,
  ServerResponse,
} from "../../services/ServiceHelper";
import { TaxService } from "../../services/TaxService";
import InputHelper from "../../utilities/InputHelpers";
import { ValidateInput } from "../../utilities/subscribe/validateInput";

declare const window: any;
declare const gtag: any;

const postProTransaction = (user: ApplicationUser) => {
  const proSubscription = user.proSubscription;
  if ("gtag" in window) {
    window.gaItem = {
      id: "2c92a0fb501f2f53015021c3b9082cf1",
      name: proSubscription?.planName,
      brand: "SermonCentral",
      category: "PRO Subscription",
      quantity: "1",
      price: proSubscription?.nextBillPrice,
    };
    window.gaTransaction = {
      transaction_id: user.id,
      affiliation: "SermonCentralPro",
      value: proSubscription?.nextBillPrice,
      currency: user.currency || "USD",
      shipping: "0",
      tax: "0",
      items: [window.gaItem],
      coupon: "",
    };

    gtag("event", "purchase", window.gaTransaction);
  }
};

interface Props {
  config: AppConfig;
  user: ApplicationUser | null;
  handleUpdateUser: (user: ApplicationUser | null) => void;
}

export function SubscribeCreateBundledMakerAccount(props: Props) {
  const [errors, setErrors] = useState<FieldValidationError[]>([]);
  const [loading, setLoading] = useState(true);
  const [proPlan, setProPlan] = useState<ProPlan | undefined>(undefined);
  const [creditCardNumber, setCardNumber] = useState("");
  const [cv2, setCv2] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [enable, setEnabled] = useState(true);
  const [hasHadProTrial, setHasHadProTrial] = useState<boolean>(false);
  const [hasHadMakerTrial, setHasHadMakerTrial] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const [validatedPostalCodeLocation, setValidatedPostalCodeLocation] =
    useState<PostalCodeLocation | undefined>(undefined);
  const [selectedCountryShortCode, setSelectedCountryShortCode] = useState<
    string | undefined
  >(undefined);
  const [taxAmount, setTaxAmount] = useState<number>(0);

  useEffect(() => {
    async function mountBundle() {
      const results = await Promise.all([
        ProSubscriptionService.getProPlans(),
        AccountService.hasHadTrial(),
        AccountService.hasHadMakerTrial(),
        ProSubscriptionService.getCountries(),
      ]);

      const [
        planResults,
        hasHadProTrailResult,
        hasHadMakerTrialResult,
        locationResult,
      ] = results;
      let plan = planResults.find((p) => p.id === "AnnualPlus");
      if (!plan) {
        const planError: FieldValidationError = {
          field: "",
          errors: [
            "We were unable to retrieve the PRO plan. Please try again.",
          ],
        };
        setErrors([planError]);
      } else {
        setProPlan(plan);
      }

      if (ServerResponse.isError(locationResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve location. Please try again."],
        };
        setErrors([serverError]);
      } else if (ServerResponse.isSuccess<Country[]>(locationResult)) {
        setCountries(locationResult.data);
      }

      setHasHadMakerTrial(hasHadMakerTrialResult);
      setHasHadProTrial(hasHadProTrailResult);
      setLoading(false);
    }
    mountBundle();
  }, []);

  useEffect(() => {
    if (!loading) {
      window.top.postMessage(
        { message: "loadingCompleted", height: document.body.scrollHeight },
        "*"
      );
    }
  }, [loading]);

  const checkTax = async (postalCode: string) => {
    const selectedProPlan = proPlan;

    if (!selectedProPlan) {
      return;
    }

    let totalPrice = ProSubscriptionService.getProPlanPriceIncludingDiscount(
      selectedProPlan,
      undefined
    );

    var taxRequest = await TaxService.getTaxAmount(
      postalCode,
      true,
      +totalPrice
    );

    setTaxAmount(taxRequest == null ? 0 : taxRequest);
  };

  const validateExpiryMonth = () => {
    var validationError = ValidateInput.CreditCardExpiryMonth(expiryMonth);

    if (validationError != null) {
      setErrors([validationError]);
      return false;
    } else {
      return true;
    }
  };

  const validateExpiryYear = () => {
    var validationError = ValidateInput.CreditCardExpiryYear(expiryYear);

    if (validationError != null) {
      setErrors([validationError]);
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!enable) {
      return;
    }

    if (!validateExpiryMonth()) {
      return;
    }

    if (!validateExpiryYear()) {
      return;
    }

    if (postalCode.length === 0) {
      const serverError: FieldValidationError = {
        field: "CreditCardPostalCode",
        errors: ["Zip/Postal code is required"],
      };
      setErrors([serverError]);
      return;
    } else {
      if (selectedCountryShortCode) {
        setValidatedPostalCodeLocation({
          country: selectedCountryShortCode,
          region: "",
          postalCode: postalCode,
        });
      } else {
        let postalCodeLocation = await PostalCodeService.getUSCanPostalCode(
          postalCode
        );

        if (postalCodeLocation === null) {
          const serverError: FieldValidationError = {
            field: "UnknownPostalCode",
            errors: ["Please select your country"],
          };
          setErrors([serverError]);
          setShowCountries(true);
          return;
        } else {
          setValidatedPostalCodeLocation(postalCodeLocation);
        }
      }
    }
  };

  useEffect(() => {
    async function submitData() {
      const postalLocation = validatedPostalCodeLocation!;
      const expirationYear: number =
        expiryYear.length === 4 ? Number(expiryYear) : Number(20 + expiryYear);
      const expirationMonth: number = Number(expiryMonth);

      setEnabled(false);
      const response =
        await ProSubscriptionService.bundleAnnualPlusAndSermonMaker(
          creditCardNumber,
          cv2,
          expirationMonth,
          expirationYear,
          postalLocation.postalCode,
          postalLocation.region,
          postalLocation.country
        );

      if (ServerResponse.isSuccess<ApplicationUser>(response)) {
        postProTransaction(response.data);
        window.top.postMessage({ message: "bundleCreated" }, "*");
      } else if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          setErrors([serverError]);
          setEnabled(true);
        } else {
          setErrors(response.errors);
          setEnabled(true);
        }
      } else if (ServerResponse.isModelValidation(response)) {
        setErrors(response.errors);
        setEnabled(true);
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        setErrors([serverError]);
        setEnabled(true);
      }
    }

    if (validatedPostalCodeLocation) {
      submitData();
    }
  }, [validatedPostalCodeLocation]);

  const determineTrialText = () => {
    if (hasHadProTrial && hasHadMakerTrial)
      return ". You accounts will be billed today and activate with full benefits. ";

    if (hasHadProTrial && !hasHadMakerTrial) {
      return ". After 14 days, your Sermon Maker account will bill and activate with full benefits. ";
    }

    if (!hasHadProTrial && hasHadMakerTrial) {
      return ". After 14 days, your SermonCentral Pro account will bill and activate with full benefits. ";
    }

    return ". After 14 days, your accounts will bill and activate with full benefits. ";
  };

  if (loading) {
    return (
      <div>
        <div
          className="row"
          style={{
            backgroundColor: "#FFF",
            height: "-webkit-fill-available",
            padding: 16,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: 400,
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              className="spinner-border"
              role="status"
              style={{ width: 30, height: 30 }}
            >
              <span className="sr-only">Loading...</span>
            </div>
            <div style={{ marginLeft: 14, fontWeight: 400, opacity: 0.9 }}>
              Fetching Bundle Plan...
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#FFF",
      }}
    >
      <div
        className="row"
        style={{
          backgroundColor: "#FFF",
          height: "-webkit-fill-available",
          padding: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {FieldValidationError.hasGenericError(errors) && (
          <div
            style={{ width: "100%", textAlign: "center" }}
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: FieldValidationError.getGenericErrorSummary(errors),
            }}
          />
        )}
        <form target="_parent" method="post" onSubmit={handleFormSubmit}>
          {proPlan && (
            <div className="col-lg-12 ">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    style={{ marginBottom: 8, fontWeight: 500, opacity: 0.9 }}
                  >
                    PRO Plus
                  </div>
                  <div
                    className="outline-selected-plan-box selected-plan-box gray-scale"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontWeight: 500, opacity: 0.6 }}>
                      Billed Annually
                    </div>
                    <div
                      style={{
                        fontWeight: 500,
                        fontSize: 24,
                        color: "#3b9d42",
                        marginBottom: -7,
                      }}
                    >
                      ${proPlan.price} {!hasHadProTrial && " ($0 Today)"}
                    </div>
                    {taxAmount > 0 && (
                      <small
                        style={{ fontSize: 12, color: "#000", opacity: 0.6 }}
                      >
                        *{proPlan.price + taxAmount} including tax
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-lg-12" style={{ marginTop: 15 }}>
            <div className="row">
              <div className="col-sm-12">
                <div style={{ marginBottom: 8, fontWeight: 500, opacity: 0.9 }}>
                  Sermon Maker
                </div>
                <div
                  className="outline-selected-plan-box selected-plan-box gray-scale"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ fontWeight: 500, opacity: 0.6 }}>
                    Billed Monthly
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: 24,
                      color: "#3b9d42",
                    }}
                  >
                    $9.99 {!hasHadMakerTrial && " ($0 Today)"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div
              style={{
                marginBottom: 8,
                fontWeight: 500,
                opacity: 0.9,
                marginTop: 24,
              }}
            >
              Your Billing Details
            </div>
            <div className="faux-cc-input input-soft-border">
              <div className="row no-gutters" style={{ height: "100%" }}>
                <div className="col-8">
                  <input
                    maxLength={16}
                    autoComplete="cc-number"
                    className="cc-input-part form-control-lg form-control input-soft-border gray-scale-font pl-3"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    placeholder="Card Number"
                    type="text"
                    required
                    value={creditCardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                    onKeyDown={(e) =>
                      InputHelper.onkeyDownNumbersOnly(e, creditCardNumber)
                    }
                  />
                </div>
                <div className="col-4">
                  <div id="expiry-container" className="d-flex">
                    <input
                      className="cc-input-part form-control-lg form-control gray-scale-font"
                      autoComplete="cc-exp-month"
                      placeholder="MM"
                      type="text"
                      required
                      value={expiryMonth}
                      onChange={(e) => setExpiryMonth(e.target.value)}
                      onKeyDown={(e) =>
                        InputHelper.onkeyDownNumbersOnly(e, expiryMonth, 2)
                      }
                      onBlur={(e) =>
                        setExpiryMonth(
                          InputHelper.onBlurPadLeftZero(e, expiryMonth)
                        )
                      }
                    />
                    <span className="gray-scale-font gray-scale-opacity">
                      /
                    </span>
                    <input
                      className="cc-input-part form-control-lg form-control  gray-scale-font"
                      autoComplete="cc-exp-year"
                      placeholder="YY"
                      type="text"
                      required
                      value={expiryYear}
                      onChange={(e) => setExpiryYear(e.target.value)}
                      onKeyDown={(e) =>
                        InputHelper.onkeyDownNumbersOnly(e, expiryYear, 2)
                      }
                      onBlur={(e) =>
                        setExpiryYear(
                          InputHelper.onBlurPadLeftZero(e, expiryYear)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 custom-cc-feedback">
            {FieldValidationError.isFieldInError(
              errors,
              "CreditCardNumber"
            ) && (
              <div
                className="invalid-feedback"
                dangerouslySetInnerHTML={{
                  __html: FieldValidationError.getFieldErrorSummary(
                    errors,
                    "CreditCardNumber"
                  ),
                }}
              />
            )}
            {FieldValidationError.isFieldInError(
              errors,
              "CreditCardExpirationMonth"
            ) && (
              <div
                className="invalid-feedback"
                dangerouslySetInnerHTML={{
                  __html: FieldValidationError.getFieldErrorSummary(
                    errors,
                    "CreditCardExpirationMonth"
                  ),
                }}
              />
            )}
            {FieldValidationError.isFieldInError(
              errors,
              "CreditCardExpirationYear"
            ) && (
              <div
                className="invalid-feedback"
                dangerouslySetInnerHTML={{
                  __html: FieldValidationError.getFieldErrorSummary(
                    errors,
                    "CreditCardExpirationYear"
                  ),
                }}
              />
            )}
          </div>
          <div style={{ paddingTop: 16 }} className="col-sm-12 mb-3">
            <div className="faux-zip-cvc-input input-soft-border">
              <div className="row no-gutters" style={{ height: "100%" }}>
                <div className="col-8">
                  <input
                    type="text"
                    maxLength={10}
                    autoComplete="shipping postal-code"
                    placeholder="Zip/Postal Code"
                    className="cc-input-part form-control-lg form-control input-soft-border gray-scale-font pl-3"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    id="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    onBlur={(e) => checkTax(e.target.value as string)}
                  />
                </div>
                <div className="col-4">
                  <input
                    id="cvc-input"
                    maxLength={4}
                    className="cc-input-part form-control-lg form-control input-soft-border gray-scale-font pl-2"
                    placeholder="CVC"
                    type="text"
                    autoComplete="cc-csc"
                    required
                    value={cv2}
                    onChange={(e) => setCv2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 custom-cc-feedback">
            {FieldValidationError.isFieldInError(
              errors,
              "CreditCardPostalCode"
            ) && (
              <div
                className="invalid-feedback"
                dangerouslySetInnerHTML={{
                  __html: FieldValidationError.getFieldErrorSummary(
                    errors,
                    "CreditCardPostalCode"
                  ),
                }}
              />
            )}
            {FieldValidationError.isFieldInError(
              errors,
              "CreditCardSecurityCode"
            ) && (
              <div
                className="invalid-feedback"
                dangerouslySetInnerHTML={{
                  __html: FieldValidationError.getFieldErrorSummary(
                    errors,
                    "CreditCardSecurityCode"
                  ),
                }}
              />
            )}
          </div>
          {showCountries && (
            <div className="col-12 mb-3" style={{ paddingTop: 4 }}>
              <div className="input-group input-soft-border">
                <select
                  className="form-control form-control-lg select-soft"
                  id="country"
                  value={
                    selectedCountryShortCode ? selectedCountryShortCode : ""
                  }
                  onChange={(e) => setSelectedCountryShortCode(e.target.value)}
                >
                  <option value="">Select country...</option>
                  {countries.map((c) => (
                    <option key={c.twoCharCode} value={c.threeCharCode}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              {FieldValidationError.isFieldInError(
                errors,
                "UnknownPostalCode"
              ) && (
                <div
                  className="need-more-feedback"
                  dangerouslySetInnerHTML={{
                    __html: FieldValidationError.getFieldErrorSummary(
                      errors,
                      "UnknownPostalCode"
                    ),
                  }}
                />
              )}
            </div>
          )}
          <div className="col-12" style={{ paddingTop: 28 }}>
            <button
              style={{
                width: "100%",
                padding: 16,
                backgroundColor: "#3b9d42",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 22,
                borderRadius: 4,
                border: "none",
              }}
              type="submit"
              disabled={!enable}
            >
              {enable ? (
                !hasHadProTrial || !hasHadMakerTrial ? (
                  "Start Your Free Trial!"
                ) : (
                  "Sign up"
                )
              ) : (
                <>
                  PLEASE WAIT
                  <div
                    className="spinner-border spinner-border-sm ml-4 mb-1"
                    role="status"
                  />
                </>
              )}
            </button>
          </div>
          <div className="col-12" style={{ paddingTop: 14, maxWidth: 574 }}>
            By signing up, you are agreeing to our{" "}
            <a
              href="https://www.sermoncentral.com/Content/termsandconditions"
              target="_blank"
              style={{ color: "#3b9d42", fontWeight: 500 }}
            >
              Terms of Service
            </a>
            {determineTrialText()}
            Your <b>PRO Plus</b> account and your <b>Sermon Maker</b> account
            will bill as distinct charges and can be canceled any time.
          </div>
        </form>
      </div>
    </div>
  );
}
