import { WebClient, ServerResponse } from '../services/ServiceHelper';

import ApplicationUser from '../entities/ApplicationUser';

export class AuthenticationService {

    static async getAuthenticatedUser() {

        var response = await WebClient.Get<ApplicationUser>("api/Authentication/GetAuthenticatedUser");
        if (ServerResponse.isSuccess<ApplicationUser>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    static async getIsExistingUser(email: string) {

        var response = await WebClient.Post<boolean>("api/Authentication/GetIsExistingUser", { email: email });
        if (ServerResponse.isSuccess<boolean>(response)) {
            return response.data;
        }
        else {
            return false;
        }
    }

    static register(firstName: string, lastName: string, email: string, password: string) {
        return WebClient.Post<ApplicationUser>("api/Authentication/Register", { firstName: firstName, lastName: lastName, email: email, password: password });
    }

    static signIn(email: string, password: string, rememberMe: boolean, context: 'SignIn' | 'AuthChallenge') {
        return WebClient.Post<ApplicationUser>("api/Authentication/SignIn", { email: email, password: password, rememberMe: rememberMe, context: context });
    }

    static async signOut() {

        var response = await WebClient.Get<string>("api/Authentication/SignOut");
        if (ServerResponse.isSuccess<string>(response)) {
            return response.data;
        }
        else {
            return '';
        }
    }
}